<template>
	<div class="btn-container flex f-ai-c f-jc-c">
		<button @click="clickHandle">{{btnText}}</button>
	</div>
</template>
<script>
export default {
	props: {
		disabled: {
			type: Boolean,
			default: false
		},
		btnText: {
			type: String,
			default: '按钮'
		}
	},
	methods: {
		clickHandle () {
			if (this.disabled) return null;
			this.$emit('s-click');
		}
	}
};
</script>
<style lang="less" scoped>
	.btn-container {
		height: 52px;
		background-color: white;
		width: 100%;
		button {
			width: 315px;
			height: 40px;
			border: none;
			background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
			border-radius: 22.5px;
			color: #FFFFFF;
			font-size: 16px;
			font-weight: 400;
		}
	}
</style>