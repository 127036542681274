<template>
	<div class="patient">
		<div class="tips">
			根据《互联网医院管理办法》，请填写真实姓名、性别，年龄，手机号等信息，该信息仅就诊医生可见，请放心填写。
		</div>
		<div>
			<div class="field flex pr f-jc-sb f-ai-c">
				<span>姓名</span>
				<input @input="validate" v-model="name" maxlength="20" type="text" placeholder="请输入真实姓名" />
			</div>
			<div class="field flex pr f-jc-sb f-ai-c">
				<span>性别</span>
				<ul class="flex f-ai-c">
					<li @click="checkGender(1)" :class="{'active': gender === 1}">男</li>
					<li @click="checkGender(2)" :class="{'active': gender !== 1}">女</li>
				</ul>
			</div>
			<div class="field flex pr f-jc-sb f-ai-c">
				<span>年龄</span>
				<div class="f-i">
					<input @input="validate" v-model="age" type="number" placeholder="请输入年龄" />岁
				</div>
			</div>
			<div class="field flex pr f-jc-sb f-ai-c">
				<span>手机号</span>
				<input @input="validate" v-model="mobilePhone" type="number" placeholder="请输入手机号码" />
			</div>
			<div class="field flex pr f-jc-sb f-ai-c p-h">
				<span>身高</span>
				<div class="f-i">
					<input @input="validate" v-model="height" type="number" placeholder="请输入身高" />CM
				</div>
			</div>
			<div class="field flex pr f-jc-sb f-ai-c">
				<span>体重</span>
				<div class="f-i">
					<input @input="validate" v-model="weight" type="number" placeholder="请输入体重" />KG
				</div>
			</div>
		</div>
		<s-button
			:class="`sub-btn ${btnDisabled ? 'disabled' : ''}`"
			btnText="提交"
			@s-click="clickHandle"
			:disabled="btnDisabled"
		></s-button>
	</div>
</template>
<script>
import sButton from '@/views/common/button.vue';
import { patientAdd, patientAddDefault } from '@/request/api/user';
import { validatePhone } from '@/utils';
export default {
	data () {
		return {
			name: '',
			gender: 1,
			age: '',
			mobilePhone: '',
			height: '',
			weight: '',
			btnDisabled: true
		};
	},
	components: { sButton },
	methods: {
		checkGender (v) {
			this.gender = v;
		},
		validate () {
			this.btnDisabled = true;
			if (this.name && this.age && this.mobilePhone && this.gender) {
				this.btnDisabled = false;
			}
		},
		clickHandle () {
			let errMsg = '';
			let label = '就诊人';
			if (this.$route.path === '/add-patient-default') label = '用户';
			if (!this.name) errMsg = `请填写${label}姓名`;
			else if (!this.age) errMsg = `请填写${label}年龄`;
			else if (!this.mobilePhone) errMsg = '请填写手机号';
			else if (!validatePhone(this.mobilePhone)) errMsg = '请输入正确的手机号';
			if (errMsg) {
				this.$root.elMsg(errMsg, 'fail');
				return;
			}
			const reqData = {
				name: this.name,
				gender: Number(this.gender),
				age: Number(this.age),
				mobilePhone: this.mobilePhone,
				height: Number(this.height),
				weight: Number(this.weight)
			};
			this.btnDisabled = true;
			if (this.$route.path === '/add-patient-default') {
				patientAddDefault(reqData).then(data => {
					if (data && data === 'retry') {
						this.clickHandle();
					} else if (data && data.ok === 1) {
						this.$toast({
							message: '操作成功',
							onClose: () => {
								if (this.$route.path === '/add-patient-default') {
									const backUrl = window.localStorage.getItem('backUrl');
									if (backUrl) {
										window.localStorage.setItem('backUrl', '');
										this.$root.replace(backUrl);
									} else {
										this.$root.replace({ path: '/user-center' });
									}
								} else {
									this.$root.replace({ path: '/patient-list' });
								}
							}
						});
					}
				}).catch(e => {
					this.$root.elMsg(e.message, 'fail');
				});
			} else {
				patientAdd(reqData).then(data => {
					if (data && data === 'retry') {
						this.clickHandle();
					} else if (data && data.ok === 1) {
						this.$toast({
							message: '操作成功',
							onClose: () => {
								this.$root.replace({ path: '/patient-list' });
							}
						});
					}
				}).catch(e => {
					this.$root.elMsg(e.message, 'fail');
				});
			}
		},
		numberFormat (val) {
			return val.replace(/[^\d]/, '');
		}
	}
};
</script>
<style lang="less" scoped>
	.patient {
		position: relative;
	}
	.tips {
		height: 62px;
		background: #FFF7EE;
		padding: 12px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FF8D16;
		box-sizing: border-box;
	}
	.disabled {
		opacity: 0.5;
	}
	.field {
		background-color: white;
		height: 50px;
		padding: 0 15px;
		font-size: 15px;
	}
	.flex {
		&::after {
			content: '';
			background-color: #F3F3F3;
			height: 1px;
			display: block;
			position: absolute;
			bottom: 0;
			left: 15px;
			right: 15px;
		}
		&:nth-child(4)::after, &:nth-child(6)::after {
			content: '';
			height: 0;
		}
		input {
			outline: none;
			border: none;
			width: 110px;
			text-align: right;
			margin-right: 7px;
		}
		ul {
			padding: 0;
			li {
				list-style: none;
				width: 40px;
				height: 29px;
				border-radius: 4.5px;
				border: 1px solid #BEBEBE;
				text-align: center;
				line-height: 29px;
				color: #8B8B8B;
				margin-left: 15px;
				&.active {
					border-color: #FF6450;
					color: #FF6450;
				}
			}
		}
	}
	.p-h {
		margin-top: 10px;
	}
</style>